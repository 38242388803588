import React, { useState } from "react";
import "./styles.scss";
import NeAboutAgent from "../../assets/imgs/ne-about-agent.png";
import NeArrowDark from "../../assets/imgs/ne-arrow-dark.png";
import NeNipostLogo from "../../assets/imgs/ne-nipost-logo.png";
import NeDart from "../../assets/icons/ne-dart.svg";
import NeLense from "../../assets/icons/ne-lense.svg";
import NeHoboLogo from "../../assets/icons/ne-hobo-logo.svg";
import Main from "../../layouts/Main";
import NeHelpGradient from "../../assets/icons/ne-help-gradient.svg";

import Heading from "../../components/Heading";
import Button from "../../components/Button";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper";

const LIGHT = "text-primary-500 font-normal xl:font-normal md:font-normal lg:font-normal xl:font-normal"
const BOLD = "text-secondary-500 font-semibold xl:font-semibold md:font-semibold lg:font-semibold xl:font-semibold"

const About = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = React.useRef(null);
  return (
    <Main>
      <Helmet>
        <title>About | New Era</title>
        <meta name="keywords" content="" />
      </Helmet>
      <div className={"min-h-screen h-full"}>
        <section className={"sm:pt-32 pt-24 container home-header"}>
          <div className={"flex flex-wrap"}>
            <div className={"w-full"}>
              <div className={"w-full text-left mb-16"}>
                <h4 className={"text-heading-sm md:text-heading-md lg:text-heading-lg xl:text-heading-xl "}>
                  <span className={LIGHT}>WE DELIVER </span>
                  <span className={BOLD}>SENSITIVE </span>
                  <span className={LIGHT}>AND </span>
                  <br className="sm:block hidden" />
                  <span className={BOLD}>CONFIDENTIAL </span>
                  <span className={LIGHT}>DOCUMENTS</span>
                </h4>
              </div>
              <div className={"document-ads sm:h-[48rem] h-[20rem]"}></div>
            </div>
          </div>
        </section>

        <section className={"mt-20 container"}>
          <div className={"flex flex-wrap"}>
            <div className={"w-full"}>
              <div className={"sm:block hidden bg-white rounded-[32px] p-20 about-gradient relative"}>
                <h3 className={"text-secondary-500 text-heading-xs font-normal mb-4 sm:leading-10"}>WHO WE ARE</h3>
                <Heading>A unique logistics<br />company</Heading>
                <p className={"w-7/12 text-body-lg xl:text-body-xl mt-8 font-light xl:leading-[40px] pr-[8rem]"}>
                  NewEra is a unique logistics company and a subsidiary of MAG Group of Companies – a fast-growing conglomerate thriving in the financial technology and national identity management space.
                </p>
                <p className={"w-7/12 text-body-lg xl:text-body-xl mt-8 font-light xl:leading-[40px] pr-[8rem]"}>
                  As a disruptive company, we aim to solve the scarcity of efficient and secure courier service providers for sensitive and confidential documents. Our goal is to provide every institution and individual with an easy, efficient, and transparent means of sending delicate documents from one point to another without worrying about loss, theft, or unauthorized access.
                </p>
                <img className="absolute right-0 bottom-0" src={NeAboutAgent} alt="map-bg" />
              </div>
              <div className={"sm:hidden block about-gradient-mobile relative pb-[24rem]"}>
                <h3 className={"text-secondary-500 text-heading-xs font-normal mb-4 sm:leading-10"}>WHO WE ARE</h3>
                <Heading>A unique logistics<br />company</Heading>
                <p className={"w-full text-body-lg xl:text-body-xl mt-8 font-light xl:leading-[40px]"}>
                  NewEra is a unique logistics company and a subsidiary of MAG Group of Companies – a fast-growing conglomerate thriving in the financial technology and national identity management space.
                </p>
                <p className={"w-full text-body-lg xl:text-body-xl mt-8 font-light xl:leading-[40px]"}>
                  As a disruptive company, we aim to solve the scarcity of efficient and secure courier service providers for sensitive and confidential documents. Our goal is to provide every institution and individual with an easy, efficient, and transparent means of sending delicate documents from one point to another without worrying about loss, theft, or unauthorized access.
                </p>
                <img className="absolute right-0 bottom-0" src={NeAboutAgent} alt="map-bg" />
              </div>
            </div>
          </div>
        </section>

        <section className={"sm:block hidden mt-20 container"}>
          <div className={"flex flex-wrap"}>
            <div className={"w-5/12"}>
              <div className={"p-20 what-we-do-bg h-full"}></div>
            </div>
            <div className={"w-7/12"}>
              <div className={"px-10"}>
                <h3 className={"text-secondary-500 sm:text-heading-xs text-body-2xl sm:font-normal font-normal sm:mb-4"}>WHAT WE DO</h3>
                <Heading>Last-mile logistics <br className="lg:block hidden" />services</Heading>
                <p className={"text-body-lg xl:text-body-xl mt-8 font-light xl:leading-[40px] lg:pr-[8rem]"}>
                  We provide secure last-mile logistics services for confidential and sensitive documents through our extensive network across Nigeria.
                </p>
                <p className={"text-body-lg xl:text-body-xl mt-8 font-light xl:leading-[40px] lg:pr-[8rem]"}>
                  We have developed a proprietary delivery system that ensures complete security during the entire logistics process, keeping the documents private and protected. Our advanced technologies, rigorous safety protocols, and experienced professionals ensure that all documents are handled with the utmost care and arrive uncompromised at their final destination, regardless of where they have to go.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={"sm:hidden block mt-20 container"}>
          <div className={"flex flex-wrap"}>
            <div className={"w-full"}>
              <h3 className={"text-secondary-500 text-heading-xs font-normal mb-4 sm:leading-10"}>WHAT WE DO</h3>
              <Heading>Last-mile logistics<br />services</Heading>
              <p className={"text-body-lg xl:text-body-xl mt-4 font-light xl:leading-[40px]"}>
                We provide secure last-mile logistics services for confidential and sensitive documents through our extensive network across Nigeria.
              </p>
              <p className={"text-body-lg xl:text-body-xl mt-4 font-light xl:leading-[40px]"}>
                We have developed a proprietary delivery system that ensures complete security during the entire logistics process, keeping the documents private and protected. Our advanced technologies, rigorous safety protocols, and experienced professionals ensure that all documents are handled with the utmost care and arrive uncompromised at their final destination, regardless of where they have to go.
              </p>
            </div>
            <div className={"w-full mt-4 mb-24"}>
              <div className={"p-20 what-we-do-bg h-[20rem]"}></div>
            </div>
          </div>
        </section>

        <section className={"sm:py-24 py-4"}>
          <div className={"container"}>
            <div className="sm:grid grid-cols-2 gap-10">
              <div className="mission-bg sm:px-12 px-6 sm:py-12 py-6 sm:mb-0 mb-10">
                <img src={NeDart} className="sm:h-24 h-12 mb-6" alt={"NeDart"} />
                <Heading>Our Mission</Heading>
                <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px] pr-[8rem]"}>
                  Meeting the logistics needs of our clients in a seamless manner, so that they can focus on their core business.
                </p>
              </div>
              <div className="vision-bg sm:p-12 p-6">
                <img src={NeLense} className="sm:h-24 h-12 mb-6" alt={"NeDart"} />
                <Heading>Our Mission</Heading>
                <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px] pr-[8rem]"}>
                  To be the partner of choice providing integrated logistics solutions to public and private organizations and enabling them to accomplish more.
                </p>
              </div>
            </div>

          </div>
        </section>

        <section className={"py-4"}>
          <div className={"container"}>
            <Heading>Our Core Values</Heading>
            <div className="sm:flex hidden justify-between items-center">
              <p className={"text-secondary-500 text-heading-md font-medium font-normal my-10"}>0{activeIndex + 1} - 06</p>
              <img src={NeArrowDark} className="h-10 cursor-pointer" alt={"NeDart"} onClick={() => swiperRef.current.swiper.slideNext()} />
            </div>
            <div className="sm:hidden block">
              <p className={"text-primary-500 text-body-xl font-medium mb-4 font-normal my-4"}>0{activeIndex + 1} <span className={"text-[#E6E6E6]"}> - 06</span></p>
            </div>
            <div className="sm:block hidden">
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                modules={[FreeMode, Pagination]}
                className="sm:grid grid-cols-2 gap-10 h-full"
                loop={true}
                ref={swiperRef}
                onRealIndexChange={(element) => setActiveIndex(element.activeIndex)}
              >
                <SwiperSlide>
                  <div className="sm:rounded-[42px] rounded-[18px] sm:px-16 px-8 sm:pt-12 pt-6 sm:pb-20 pb-12 sm:border-2 border border-secondary-500 h-[28rem]">
                    <p className={"text-secondary-500 sm:text-heading-md text-heading-xs mb-4 sm:font-normal font-normal mt-10"}>QUALITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      As a firm committed to increasing its level of competitiveness and customer satisfaction, we pursue quality as a priority in terms of teams and services.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="rounded-[42px] px-16 pt-12 sm:pb-20 pb-12 border-2 border-secondary-500 h-[28rem]">
                    <p className={"text-secondary-500 text-heading-md font-medium mb-4 font-normal mt-10"}>AGILITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      We never stop evolving to cater to the changing needs of our clients and the uncertainties of today’s business environments.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="rounded-[42px] px-16 pt-12 sm:pb-20 pb-12 border-2 border-secondary-500 h-[28rem]">
                    <p className={"text-secondary-500 text-heading-md font-medium mb-4 font-normal mt-10"}>AFFORDABILITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      We are committed to offering services that are affordable and deliver value to our clients.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="rounded-[42px] px-16 pt-12 sm:pb-20 pb-12 border-2 border-secondary-500 h-[28rem]">
                    <p className={"text-secondary-500 text-heading-md font-medium mb-4 font-normal mt-10"}>RELIABILITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      We maintain a brand that hinges on delivering quality and timely services to customers.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="rounded-[42px] px-16 pt-12 sm:pb-20 pb-12 border-2 border-secondary-500 h-[28rem]">
                    <p className={"text-secondary-500 text-heading-md font-medium mb-4 font-normal mt-10"}>QUALITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      As a firm committed to increasing its level of competitiveness and customer satisfaction, we pursue quality as a priority in terms of teams and services.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="rounded-[42px] px-16 pt-12 sm:pb-20 pb-12 border-2 border-secondary-500 h-[28rem]">
                    <p className={"text-secondary-500 text-heading-md font-medium mb-4 font-normal mt-10"}>AGILITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      We never stop evolving to cater to the changing needs of our clients and the uncertainties of today’s business environments.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="sm:hidden block">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                freeMode={true}
                modules={[FreeMode, Pagination]}
                className="sm:grid grid-cols-2 gap-10 h-full"
                loop={true}
                ref={swiperRef}
                onRealIndexChange={(element) => setActiveIndex(element.activeIndex)}
              >
                <SwiperSlide>
                  <div className="sm:rounded-[42px] rounded-[18px] sm:px-16 px-8 sm:pt-12 pt-6 sm:pb-24 pb-12 sm:border-2 border border-secondary-500 h-[25rem]">
                    <p className={"text-secondary-500 sm:text-heading-md text-heading-xs mb-4 sm:font-normal font-normal mt-10"}>QUALITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      As a firm committed to increasing its level of competitiveness and customer satisfaction, we pursue quality as a priority in terms of teams and services.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="sm:rounded-[42px] rounded-[18px] sm:px-16 px-8 sm:pt-12 pt-6 sm:pb-24 pb-12 sm:border-2 border border-secondary-500 h-[25rem]">
                    <p className={"text-secondary-500 sm:text-heading-md text-heading-xs mb-4 sm:font-normal font-normal mt-10"}>AGILITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      We never stop evolving to cater to the changing needs of our clients and the uncertainties of today’s business environments.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="sm:rounded-[42px] rounded-[18px] sm:px-16 px-8 sm:pt-12 pt-6 sm:pb-24 pb-12 sm:border-2 border border-secondary-500 h-[25rem]">
                    <p className={"text-secondary-500 sm:text-heading-md text-heading-xs mb-4 sm:font-normal font-normal mt-10"}>AFFORDABILITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      We are committed to offering services that are affordable and deliver value to our clients.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="sm:rounded-[42px] rounded-[18px] sm:px-16 px-8 sm:pt-12 pt-6 sm:pb-24 pb-12 sm:border-2 border border-secondary-500 h-[25rem]">
                    <p className={"text-secondary-500 sm:text-heading-md text-heading-xs mb-4 sm:font-normal font-normal mt-10"}>RELIABILITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      We maintain a brand that hinges on delivering quality and timely services to customers.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="sm:rounded-[42px] rounded-[18px] sm:px-16 px-8 sm:pt-12 pt-6 sm:pb-24 pb-12 sm:border-2 border border-secondary-500 h-[25rem]">
                    <p className={"text-secondary-500 sm:text-heading-md text-heading-xs mb-4 sm:font-normal font-normal mt-10"}>QUALITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      As a firm committed to increasing its level of competitiveness and customer satisfaction, we pursue quality as a priority in terms of teams and services.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="sm:rounded-[42px] rounded-[18px] sm:px-16 px-8 sm:pt-12 pt-6 sm:pb-24 pb-12 sm:border-2 border border-secondary-500 h-[25rem]">
                    <p className={"text-secondary-500 sm:text-heading-md text-heading-xs mb-4 sm:font-normal font-normal mt-10"}>AGILITY</p>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      We never stop evolving to cater to the changing needs of our clients and the uncertainties of today’s business environments.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>

        <section className={"mt-16 sm:mt-32 happy-clerk-ads"}>
          <div className={"container"}>
            <div className={"sm:px-[4rem] pt-[8rem] sm:pt-[44rem] sm:pb-[4rem]"}>
              <p className={"text-heading-xs sm:text-heading-lg xl:text-heading-xl mt-6 xl:font-normal sm:font-normal font-normal text-secondary-500"}>
                WE HAVE DEVELOPED A PROPRIETARY DELIVERY SYSTEM THAT ENSURES COMPLETE SECURITY
              </p>
            </div>
          </div>
        </section>

        <section className={"relative services-center-bg pt-12"}>
          <div className="container">
            <div className={"w-full flex flex-col flex-wrap items-center"}>
              <Heading>How We Operate</Heading>
              <div className="sm:grid grid-cols-2 gap-10">
                <div className="sm:flex mt-12 border-b-[1px] border-b-[#707070] pb-16">
                  <div className="sm:mb-0 mb-4 bg-black rounded-full sm:w-16 sm:h-16 h-12 w-12 flex items-center flex-none justify-center mr-4">
                    <p className="sm:text-heading-lg text-heading-md sm:font-normal font-normal text-white">1</p>
                  </div>
                  <div>
                    <p className={"text-body-lg xl:text-body-2xl mb-6 font-light xl:leading-[40px]"}>
                      Our teams of professionals ensure that every aspect of our service is up to the highest standards and meets all security and privacy regulatory requirements.
                    </p>
                  </div>
                </div>
                <div className="sm:flex mt-12 border-b-[1px] border-b-[#707070] pb-16">
                  <div className="sm:mb-0 mb-4 bg-black rounded-full sm:w-16 sm:h-16 h-12 w-12 flex items-center flex-none justify-center mr-4">
                    <p className="sm:text-heading-lg text-heading-md sm:font-normal font-normal text-white">2</p>
                  </div>
                  <div>
                    <p className={"text-body-lg xl:text-body-2xl mb-6 font-light xl:leading-[40px]"}>
                      We also use advanced technology and cutting-edge logistical methods to ensure that all of our deliveries reach their destination on time and in perfect condition.
                    </p>
                  </div>
                </div>
                <div className="sm:flex mt-12 sm:border-b-[0px] sm:pb-0 border-b-[1px] border-b-[#707070] pb-16">
                  <div className="sm:mb-0 mb-4 bg-black rounded-full sm:w-16 sm:h-16 h-12 w-12 flex items-center flex-none justify-center mr-4">
                    <p className="sm:text-heading-lg text-heading-md sm:font-normal font-normal text-white">3</p>
                  </div>
                  <div>
                    <p className={"text-body-lg xl:text-body-2xl mb-6 font-light xl:leading-[40px]"}>
                      Our teams of professionals ensure that every aspect of our service is up to the highest standards and meets all security and privacy regulatory requirements.
                    </p>
                  </div>
                </div>
                <div className="sm:flex mt-12 sm:border-b-[0px] sm:pb-0 border-b-[1px] border-b-[#707070] pb-16">
                  <div className="sm:mb-0 mb-4 bg-black rounded-full sm:w-16 sm:h-16 h-12 w-12 flex items-center flex-none justify-center mr-4">
                    <p className="sm:text-heading-lg text-heading-md sm:font-normal font-normal text-white">4</p>
                  </div>
                  <div>
                    <p className={"text-body-lg xl:text-body-2xl mb-6 font-light xl:leading-[40px]"}>
                      We also use advanced technology and cutting-edge logistical methods to ensure that all of our deliveries reach their destination on time and in perfect condition.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={"w-full flex flex-col flex-wrap items-center mt-12"}>
              <Heading>Strategic Partnerships</Heading>
              <div className="sm:w-2/3 sm:text-center">
                <p className={"text-body-lg xl:text-body-2xl mb-6 mt-12 font-light xl:leading-[40px]"}>
                  Our commitment to security and customer satisfaction has been recognized by industry leaders, making us their preferred choice for partnership
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-32">
          <div className={"w-full sm:grid grid-cols-12 items-center mt-12"}>
            <div className="col-span-5 h-full bg-[#d8e1ff]">
              <div className="h-full px-6 sm:px-10 sm:py-32 py-16 flex items-center sm:justify-end gap-x-4">
                <img src={NeNipostLogo} className="lg:h-auto h-16 mb-6" alt={"NeDart"} />
                <p className={"text-body-lg xl:text-body-2xl sm:font-light font-normal xl:leading-[40px] w-1/2"}>
                  ensuring wide location coverage across Nigeria.
                </p>
              </div>
            </div>
            <div className="col-span-7 h-full bg-white">
              <div className="h-full px-6 sm:px-10 sm:py-32 py-16 flex items-center gap-x-4">
                <img src={NeHoboLogo} className="lg:h-auto h-6 mb-6" alt={"NeDart"} />
                <p className={"text-body-lg xl:text-body-2xl sm:font-light font-normal xl:leading-[40px] w-1/2"}>
                  ensuring a unique business model with an efficient delivery system.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={"mt-20 mb-72 relative"}>
          <div className="container">
            <Heading>
              Let’s help you transform<br className="sm:block hidden" />your supply chain
            </Heading>
            <p className={"xl:w-9/12 text-body-lg xl:text-body-xl mt-12 mb-6 font-light xl:leading-[40px] xl:pr-[8rem]"}>
              We never compromise when it comes to the security of our client’s documents, and are committed to making sure they reach their destinations uncompromised.
            </p>
            <div className={"mt-10 flex gap-x-4"}>
              <Button
                state={"SECONDARY"}
                text={"Contact us"}
                onClick={() => navigate("/contact")}
              />
            </div>
            <img className="absolute right-0 top-[-10rem] z-[-999]" src={NeHelpGradient} alt="map-bg" />
          </div>
        </section>
      </div>
    </Main>
  );
};

export default About;