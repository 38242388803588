const navs = [
  {
    name: "Home",
    route: "/",
  },
  {
    name: "Services",
    route: "/services",
  },
  {
    name: "Location",
    route: "/location",
  },
  {
    name: "About",
    route: "/about",
  },
  {
    name: "Contact",
    route: "/contact",
  },
];

export { navs };
