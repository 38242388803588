import React from "react";
import "./styles.scss";

const Button = (props) => {
  const { text = "Button", state = "PRIMARY", ...rest } = props;
  return (
    <div className="relative lg:w-[15rem] w-[12rem]">
      <button className={state === "PRIMARY" ? "arrow-button" : "arrow-button-black"} {...rest}><span className="arrow"></span><span className="absolute">{text}</span></button>
    </div>
  );
};

export default Button;