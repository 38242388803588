import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import NeMenuOpen from "../../assets/imgs/ne-close-icon.png";
import NeMenuClose from "../../assets/icons/ne-menu-close.svg";
import NeLogo from "../../assets/icons/ne-logo.svg";
import NeLogoLightGreen from "../../assets/imgs/ne-logo-light-green.png";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import { navs } from "../../utils/navs";

const NavBar = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  return (
    <div className={"fixed w-full z-20"}>
      <nav className={`${scrolled ? "nav-bar-floated bg-white" : "bg-transparent"}`}>
        <div className={"container"}>
          <div className={"flex items-center justify-between h-16 sm:h-20"}>
            <div className={"flex items-center"}>
              <div className={"flex-shrink-0"}>
                <a href={"/#"}>
                  <img
                    className={"lg:h-8 h-6"}
                    src={NeLogo}
                    alt={"Logo"}
                  />
                </a>
              </div>
            </div>
            <div className={"flex items-center"}>
              <div className={"hidden md:block"}>
                <div className={"ml-10 flex items-baseline lg:space-x-4"}>
                  {navs.map((n, i) => (
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "style_lg_active lg:text-sm-headline text-sm-regular"
                          : "style_lg_incative lg:text-sm-headline text-sm-regular"
                      }
                      key={i}
                      to={n.route}
                    >
                      {n.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
            <div className={"-mr-2 flex md:hidden"}>
              <button
                onClick={() => setIsOpen(!isOpen)}
                type={"button"}
                className={
                  "inline-flex items-center justify-center p-2 focus:outline-none"
                }
                aria-controls={"mobile-menu"}
                aria-expanded={"false"}
              >
                <img src={NeMenuClose} alt={"menu"} className={"h-8"} />
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter={"transition ease-out duration-100 transform"}
          enterFrom={"opacity-0 scale-95"}
          enterTo={"opacity-100 scale-100"}
          leave={"transition ease-in duration-75 transform"}
          leaveFrom={"opacity-100 scale-100"}
          leaveTo={"opacity-0 scale-95"}
          className={"absolute w-full bg-secondary-500 mobile-gradient h-[100vh] shadow top-0"}
        >
          <div className={"md:hidden"} id={"mobile-menu"}>
            <div className={"-mr-2 flex md:hidden justify-between p-4 items-center"}>
              <img
                className={"h-5"}
                src={NeLogoLightGreen}
                alt={"Logo"}
              />
              <button
                onClick={() => setIsOpen(!isOpen)}
                type={"button"}
                className={
                  "inline-flex items-center justify-center p-2 focus:outline-none"
                }
                aria-controls={"mobile-menu"}
                aria-expanded={"false"}
              >
                <img src={NeMenuOpen} alt={"menu"} className={"h-8"} />
              </button>
            </div>
            <div className={"px-2 pt-2 pb-3 space-y-1 sm:px-3 text-center"}>
              {navs.map((n, i) => (
                <div key={i + n}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "style_sm_active lg:text-sm-headline text-sm-regular leading-10"
                        : "style_sm_incative lg:text-sm-headline text-sm-regular leading-10"
                    }
                    to={n.route}
                  >
                    {n.name}
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </Transition>
      </nav>
    </div>
  );
};

export default NavBar;
