import NeInstagram from "../assets/imgs/ne-instagram.png";
import NeTwitter from "../assets/imgs/ne-twitter.png";
import NeFacebook from "../assets/imgs/ne-facebook.png";
import NeLinkedIn from "../assets/imgs/ne-linkedin.png";
const SOCIAL_ICONS = [
  {
    icon: NeInstagram,
    url: "/#",
  },
  {
    icon: NeTwitter,
    url: "/#",
  },
  {
    icon: NeFacebook,
    url: "/#",
  },
  {
    icon: NeLinkedIn,
    url: "/#",
  },
];

export { SOCIAL_ICONS };
