import React from "react";
import "./styles.scss";
import NeServicesGradient from "../../assets/icons/ne-services-gradient.svg";
import NeServicesMobileGradient from "../../assets/icons/ne-services-mobile-gradient.svg";
import Main from "../../layouts/Main";
import Button from "../../components/Button";
import TitleText from "../../components/TitleText";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  return (
    <Main>
      <Helmet>
        <title>Logistics | New Era</title>
        <meta name="keywords" content="logistics, Document Delivery, Secured Delivery" />
      </Helmet>
      <div className={"min-h-screen h-full"}>
        <section className={"sm:pt-32 pt-24 container home-header"}>
          <div className={"flex flex-wrap"}>
            <div className={"w-full"}>
              <div className={"w-full text-left mb-16"}>
                <h4 className={"text-primary-500 text-heading-sm md:text-heading-md lg:text-heading-lg xl:text-heading-xl font-normal xl:font-normal md:font-normal lg:font-normal xl:font-normal"}>
                  DELICATE DOCUMENTS
                </h4>
                <h4 className={"text-secondary-500 text-heading-sm md:text-heading-md lg:text-heading-lg xl:text-heading-xl font-semibold xl:font-semibold md:font-semibold lg:font-semibold xl:font-semibold"}>
                  DELIVERED CONFIDENTIALLY
                </h4>
              </div>
              <div className={"services-ads xl:px-[4rem] px-[2rem] xl:pt-[24rem] pt-[12rem] sm:pb-[8rem] pb-[4rem]"}>
                <div className=" sm:block hidden w-1/2">
                  <p className={"text-heading-xs mt-6 font-normal lh-5 text-white"}>
                    We understand the world of sensitive information. That is why we have developed the most protected, reliable, and efficient system for secure last-mile logistics of confidential documents.
                  </p>
                </div>
                <div className={"mt-10 sm:flex hidden gap-x-4"}>
                  <Button
                    state={"PRIMARY"}
                    text={"Request Quote"}
                    onClick={() => navigate("/contact")}
                  />
                  <Button
                    state={"PRIMARY"}
                    text={"Talk to sales"}
                    onClick={() => navigate("/contact")}
                  />
                </div>
              </div>
              <div className="sm:hidden block">
                <p className={"text-body-xl mt-6 font-normal lh-5 text-secondary-500"}>
                  We understand the world of sensitive information. That is why we have developed the most protected, reliable, and efficient system for secure last-mile logistics of confidential documents.
                </p>
              </div>
              <div className={"mt-10 sm:hidden flex gap-x-4"}>
                <Button
                  state={"SECONDARY"}
                  text={"Request Quote"}
                  onClick={() => navigate("/contact")}
                />
                <Button
                  state={"SECONDARY"}
                  text={"Talk to sales"}
                  onClick={() => navigate("/contact")}
                />
              </div>
            </div>
          </div>
        </section>

        <section className={"mt-20 relative"}>
          <div className="container">
            <h4 className={"sm:hidden block text-center text-heading-sm sm:text-heading-lg xl:text-heading-xl text-secondary-500 font-semibold sm:font-semibold"}>
              SERVICES
            </h4>
            <div className={"flex flex-wrap mt-6 sm:mt-20"}>
              <div className={"w-full"}>
                <div className={"flex flex-wrap justify-center duration-300"}>
                  <div className={"w-full sm:w-6/12"}>
                    <h4 className={"text-heading-sm sm:text-heading-lg xl:text-heading-xl text-primary-500 sm:mb-0 mb-2 font-semibold sm:font-semibold"}>
                      Schools &<br />Institutions
                    </h4>
                    <p className={"sm:w-9/12 w-full text-body-lg xl:text-body-xl mt-12 mb-6 font-light xl:leading-[40px] sm:pr-[8rem]"}>
                      Schools and educational institutions across the country can rely on us for the secure logistics of sensitive school records such as:
                    </p>
                    <div className={"mt-10 sm:flex hidden gap-x-4"}>
                      <Button
                        state={"SECONDARY"}
                        text={"Request Quote"}
                        onClick={() => navigate("/contact")}
                      />
                      <Button
                        state={"SECONDARY"}
                        text={"Talk to sales"}
                        onClick={() => navigate("/contact")}
                      />
                    </div>
                    <img className="sm:block hidden absolute left-0 top-[-10rem] z-[-999]" src={NeServicesGradient} alt="map-bg" />
                    <img className="sm:hidden block absolute left-0 top-[-10rem] z-[-999]" src={NeServicesMobileGradient} alt="map-bg" />
                  </div>
                  <div className={"w-full sm:w-6/12"}>
                    <TitleText text={"Transcripts & Results"} isFirstItem={true} />
                    <TitleText text={"Reports & Assessments"} />
                    <TitleText text={"Certificates & Degrees"} />
                    <TitleText text={"Diplomas"} />
                    <TitleText text={"Student medical record"} underline={false} isLastItem={true} />
                  </div>
                  <div className={"mt-10 sm:hidden flex gap-x-4"}>
                    <Button
                      state={"SECONDARY"}
                      text={"Request Quote"}
                      onClick={() => navigate("/contact")}
                    />
                    <Button
                      state={"SECONDARY"}
                      text={"Talk to sales"}
                      onClick={() => navigate("/contact")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={"sm:mt-20 relative"}>
          <div className="container border-t-[0.1px] border-t-[#F1F1F1]">
            <div className={"flex flex-wrap mt-20"}>
              <div className={"w-full"}>
                <div className={"flex flex-wrap justify-center duration-300"}>
                  <div className={"w-full sm:w-6/12"}>
                    <h4 className={"text-heading-sm sm:text-heading-lg xl:text-heading-xl text-primary-500 sm:mb-0 mb-2 font-semibold sm:font-semibold"}>
                      Corporates
                    </h4>
                    <p className={"xl:w-9/12 w-full text-body-lg xl:text-body-xl mt-6 sm:mt-12 mb-6 font-light xl:leading-[40px] xl:pr-[8rem]"}>
                      For businesses and organizations across the country, we guarantee dependable logistics of confidential documents such as:
                    </p>
                    <div className={"mt-10 sm:flex hidden gap-x-4"}>
                      <Button
                        state={"SECONDARY"}
                        text={"Request Quote"}
                        onClick={() => navigate("/contact")}
                      />
                      <Button
                        state={"SECONDARY"}
                        text={"Talk to sales"}
                        onClick={() => navigate("/contact")}
                      />
                    </div>
                  </div>
                  <div className={"w-full sm:w-6/12"}>
                    <TitleText text={"Employment contracts"} isFirstItem={true} />
                    <TitleText text={"Pay slips & Bank statements"} />
                    <TitleText text={"Tax forms"} />
                    <TitleText text={"Court documents"} />
                    <TitleText text={"CCTV recordings"} />
                    <TitleText text={"Classified proposals"} />
                    <TitleText text={"Employment IDs"} />
                    <TitleText text={"ATM cards"} underline={false} isLastItem={true} />
                  </div>
                  <div className={"mt-10 sm:hidden flex gap-x-4"}>
                    <Button
                      state={"SECONDARY"}
                      text={"Request Quote"}
                      onClick={() => navigate("/contact")}
                    />
                    <Button
                      state={"SECONDARY"}
                      text={"Talk to sales"}
                      onClick={() => navigate("/contact")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={"sm:mt-20 relative services-center-bg"}>
          <div className="container border-t-[0.1px] border-t-[#F1F1F1]">
            <div className={"flex flex-wrap mb-10 mt-10 xl:mt-20 xl:mb-20"}>
              <div className={"w-full"}>
                <div className={"flex flex-wrap justify-center duration-300"}>
                  <div className={"w-full sm:w-6/12"}>
                    <h4 className={"text-heading-sm sm:text-heading-lg xl:text-heading-xl text-primary-500 sm:mb-0 mb-2 font-semibold sm:font-semibold"}>
                      Government <br className="sm:block hidden" />Agencies
                    </h4>
                    <p className={"xl:w-9/12 w-full text-body-lg xl:text-body-xl mt-6 sm:mt-12 mb-6 font-light xl:leading-[40px] xl:pr-[8rem]"}>
                      We maintain the highest level of compliance with data protection and privacy standards while receiving and delivering government-issued identification documents such as:
                    </p>
                    <div className={"mt-10 sm:flex hidden gap-x-4"}>
                      <Button
                        state={"SECONDARY"}
                        text={"Request Quote"}
                        onClick={() => navigate("/contact")}
                      />
                      <Button
                        state={"SECONDARY"}
                        text={"Talk to sales"}
                        onClick={() => navigate("/contact")}
                      />
                    </div>
                  </div>
                  <div className={"w-full sm:w-6/12"}>
                    <TitleText text={"Driver's License"} isFirstItem={true} />
                    <TitleText text={"Permanent Voters cars (PVC)"} />
                    <TitleText text={"NIN registration documents"} />
                    <TitleText text={"National & International Passport"} />
                    <TitleText text={"Visa documents"} underline={false} isLastItem={true} />
                  </div>
                  <div className={"mt-10 sm:hidden flex gap-x-4"}>
                    <Button
                      state={"SECONDARY"}
                      text={"Request Quote"}
                      onClick={() => navigate("/contact")}
                    />
                    <Button
                      state={"SECONDARY"}
                      text={"Talk to sales"}
                      onClick={() => navigate("/contact")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container border-t-[0.1px] border-t-[#F1F1F1]">
            <div className={"flex flex-wrap mb-20 xl:mt-20 xl:mb-20"}>
              <div className={"w-full"}>
                <div className={"flex flex-wrap justify-center duration-300"}>
                  <div className={"w-full sm:w-6/12"}>
                    <h4 className={"text-heading-sm sm:text-heading-lg xl:text-heading-xl text-primary-500 sm:mb-0 mb-2 font-semibold sm:font-semibold"}>
                      Bulk <br className="sm:block hidden" />Senders
                    </h4>
                    <p className={"xl:w-9/12 w-full text-body-lg xl:text-body-xl mt-6 sm:mt-12 mb-6 font-light xl:leading-[40px] xl:pr-[8rem]"}>
                      We operate a typical bulk sender and unique receiver model to meet the logistics needs of clients with significant traffic of sensitive documents to be delivered – including regular and ad-hoc courier, and home delivery.
                    </p>
                    <div className={"mt-10 flex gap-x-4"}>
                      <Button
                        state={"SECONDARY"}
                        text={"Request Quote"}
                        onClick={() => navigate("/contact")}
                      />
                      <Button
                        state={"SECONDARY"}
                        text={"Talk to sales"}
                        onClick={() => navigate("/contact")}
                      />
                    </div>
                  </div>
                  <div className={"w-full sm:w-6/12 sm:mt-0 mt-24"}>
                    <h4 className={"text-heading-sm sm:text-heading-lg xl:text-heading-xl text-primary-500 sm:mb-0 mb-2 font-semibold sm:font-semibold"}>
                      Personal Courier <br className="sm:block hidden" />Services
                    </h4>
                    <p className={"xl:w-9/12 w-full text-body-lg xl:text-body-xl mt-6 sm:mt-12 mb-6 font-light xl:leading-[40px] xl:pr-[8rem]"}>
                      We offer one-time and recurring home delivery, and special-hours delivery services for individuals who prefer to receive their documents at home or any location of choice instead of the post office.
                    </p>
                    <div className={"mt-10 flex gap-x-4"}>
                      <Button
                        state={"SECONDARY"}
                        text={"Request Quote"}
                        onClick={() => navigate("/contact")}
                      />
                      <Button
                        state={"SECONDARY"}
                        text={"Talk to sales"}
                        onClick={() => navigate("/contact")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container border-t-[0.1px] border-t-[#F1F1F1]">
            <div className={"flex flex-wrap xl:mt-20"}>
              <div className={"w-full"}>
                <div className={"flex flex-wrap justify-center duration-300"}>
                  <div className={"w-full sm:w-6/12"}>
                    <h4 className={"text-heading-sm sm:text-heading-lg xl:text-heading-xl text-primary-500 sm:mb-0 mb-2 font-semibold sm:font-semibold"}>
                      Value-added <br className="sm:block hidden" />services
                    </h4>
                    <p className={"xl:w-9/12 w-full text-body-lg xl:text-body-xl mt-6 sm:mt-12 mb-6 font-light xl:leading-[40px] xl:pr-[8rem]"}>
                      We exist to ease the tension that comes with moving sensitive and confidential documents from point A to point B. Our additional services include
                    </p>
                    <div className={"mt-10 sm:flex hidden gap-x-4"}>
                      <Button
                        state={"SECONDARY"}
                        text={"Request Quote"}
                        onClick={() => navigate("/contact")}
                      />
                      <Button
                        state={"SECONDARY"}
                        text={"Talk to sales"}
                        onClick={() => navigate("/contact")}
                      />
                    </div>
                  </div>
                  <div className={"w-full sm:w-6/12"}>
                    <TitleText text={"Return shipping handling"} isFirstItem={true} />
                    <TitleText text={"Customs documentation preparation"} />
                    <TitleText text={"Consolidation of multiple carriers on a single shipment"} />
                    <TitleText text={"Delivery Scheduling"} />
                    <TitleText text={"Real-time tracking"} />
                    <TitleText text={"Delivery report preparation"} underline={false} isLastItem={true} />
                  </div>
                  <div className={"mt-10 sm:hidden flex gap-x-4"}>
                    <Button
                      state={"SECONDARY"}
                      text={"Request Quote"}
                      onClick={() => navigate("/contact")}
                    />
                    <Button
                      state={"SECONDARY"}
                      text={"Talk to sales"}
                      onClick={() => navigate("/contact")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={"mt-32 services-lady-bg"}>
          <div className={"container"}>
            <div className={"h-[16rem] sm:h-[38rem] xl:h-[48rem]"}></div>
          </div>
        </section>

        <section className="mb-32">
          <div className={"container"}>
            <div className="flex flex-col items-center">
              <h4 className={"text-center text-heading-xs xl:text-heading-md xl:text-heading-xl sm:mb-18 sm:mt-10"}>
                <span className="font-normal sm:font-normal text-primary-500">Throughout the entire delivery process, you can expect </span>
                <span className="font-semibold sm:font-semibold text-secondary-500">quality</span>
                <span className="font-normal sm:font-normal text-primary-500">, </span>
                <span className="font-semibold sm:font-semibold text-secondary-500">agility</span>
                <span className="font-normal sm:font-normal text-primary-500">, </span>
                <span className="font-semibold sm:font-semibold text-secondary-500">affordability</span>
                <span className="font-normal sm:font-normal text-primary-500">, and </span>
                <span className="font-semibold sm:font-semibold text-secondary-500">reliability</span>
                <span className="font-normal sm:font-normal text-primary-500">.</span>
              </h4>
              <div className={"mt-10 flex gap-x-4"}>
                <Button
                  state={"SECONDARY"}
                  text={"Request Quote"}
                  onClick={() => navigate("/contact")}
                />
                <Button
                  state={"SECONDARY"}
                  text={"Learn more"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Main>
  );
};

export default Services;