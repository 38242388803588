import React from "react";
import "./styles.scss";
import NeMapBg from "../../assets/icons/ne-map-bg.svg";
import NeMap from "../../assets/imgs/ne-map.png";
import Main from "../../layouts/Main";
import Button from "../../components/Button";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const LIGHT = "text-primary-500 font-normal xl:font-normal md:font-normal lg:font-normal xl:font-normal"
const BOLD = "text-secondary-500 font-semibold xl:font-semibold md:font-semibold lg:font-semibold xl:font-semibold"

const Location = () => {
  const navigate = useNavigate();
  return (
    <Main>
      <Helmet>
        <title>Last-Mile Delivery | New Era</title>
        <meta name="keywords" content="Last-Mile Delivery" />
      </Helmet>
      <div className={"min-h-screen h-full"}>
        <section className={"sm:pt-32 pt-24 container home-header"}>
          <div className={"flex flex-wrap"}>
            <div className={"w-full"}>
              <div className={"w-full text-left mb-16"}>
                <h4 className={"text-heading-sm md:text-heading-md lg:text-heading-lg xl:text-heading-xl "}>
                  <span className={LIGHT}>WE’RE NIGERIA'S PREFERRED LOGISTICS PARTNER FOR </span>
                  <span className={BOLD}>SECURE LAST-MILE DELIVERY </span>
                  <span className={LIGHT}>OF </span>
                  <span className={BOLD}>SENSITIVE </span>
                  <span className={LIGHT}>AND </span>
                  <span className={BOLD}>CONFIDENTIAL </span>
                  <span className={LIGHT}>DOCUMENTS.</span>
                </h4>
              </div>
              <div className={"delivery-ads xl:px-[4rem] px-[2rem] xl:pt-[24rem] pt-[12rem] sm:pb-[8rem] pb-[4rem]"}>
                <div className=" sm:block hidden w-1/2">
                  <p className={"text-heading-xs mt-6 font-normal lh-5 text-white"}>
                    With our secure last-mile delivery service, you can trust that your documents will arrive safe and sound, no matter where they have to go.
                  </p>
                </div>
                <div className={"mt-10 sm:flex hidden gap-x-4"}>
                  <Button
                    state={"PRIMARY"}
                    text={"Request Quote"}
                    onClick={() => navigate("/contact")}
                  />
                  <Button
                    state={"PRIMARY"}
                    text={"Talk to sales"}
                    onClick={() => navigate("/contact")}
                  />
                </div>
              </div>
              <div className="sm:hidden block">
                <p className={"text-body-xl mt-6 font-normal lh-5 text-secondary-500"}>
                  With our secure last-mile delivery service, you can trust that your documents will arrive safe and sound, no matter where they have to go.
                </p>
              </div>
              <div className={"mt-10 sm:hidden flex gap-x-4"}>
                <Button
                  state={"SECONDARY"}
                  text={"Request Quote"}
                  onClick={() => navigate("/contact")}
                />
                <Button
                  state={"SECONDARY"}
                  text={"Learn more"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
          </div>
        </section>

        <section className={"mt-20 relative"}>
          <div className="container">
            <div className={"flex flex-wrap mt-20"}>
              <div className={"w-full"}>
                <img className="h-[20rem] sm:h-[40rem] xl:h-[60rem] mb-[10rem]" src={NeMap} alt="map-bg" />
                <img className="absolute right-0 top-[0rem] sm:top-[-4rem] xl:top-[-10rem] z-[-999] sm:h-[60rem] xl:h-[90rem]" src={NeMapBg} alt="map-bg" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Main>
  );
};

export default Location;