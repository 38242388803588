import React from "react";

const TitleText = ({ text, underline = true, isFirstItem = false, isLastItem = false, textColor = "" }) => {
  return (
    <div className="sm:text-left text-center">
      <h1 className={`w-full text-body-md sm:text-body-2xl xl:text-heading-sm sm:font-normal font-medium ${underline && 'border-b-[0.5px] border-b-[#707070]'} ${isFirstItem && 'xl:pb-8 pb-4'} ${isLastItem && 'xl:pt-8 pt-4'} ${!isLastItem && !isFirstItem && 'xl:py-8 py-4'} ${textColor}`}>{text}</h1>
    </div>
  );
};

export default TitleText;