import React from "react";

const Heading = ({ children }) => {
  return (
    <h4 className={"text-heading-sm sm:text-heading-md xl:text-heading-xl text-primary-500 sm:mb-0 mb-2 font-semibold sm:font-semibold"}>
      {children}
    </h4>
  );
};

export default Heading;