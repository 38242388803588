import NeMessage from "../assets/imgs/ne-message.png";
import NeLocation from "../assets/imgs/ne-location.png";
import NePeace from "../assets/imgs/ne-peace.png";
import NeDataProtection from "../assets/imgs/ne-data-protection.png";
import NeVisibility from "../assets/imgs/ne-visibility.png";
import NeTimely from "../assets/imgs/ne-timely.png";

const VALUES = [
  {
    name: "Convenient mailing",
    icon: NeMessage,
    message:
      "Send us your documents from anywhere in Nigeria; we will receive them at our central hub in Lagos and Abuja and dispatch them to their appropriate destinations.",
  },
  {
    name: "Reliable last-mile delivery",
    icon: NeLocation,
    message:
      "We use a customised tracking system to ensure that all documents are securely delivered and placed into the intended recipients’ care.",
  },
  {
    name: "Complete peace of mind",
    icon: NePeace,
    message:
      "You can rest easy knowing that your sensitive documents will be handled efficiently, securely, and confidentially.",
  },
  {
    name: "Document & data protection",
    icon: NeDataProtection,
    message:
      "Our advanced technologies and rigorous safety protocols guarantee the protection of data and documents from unauthorized access.",
  },
  {
    name: "End-to-end visibility",
    icon: NeVisibility,
    message:
      "We will ensure you know where your documents are and when they will arrive by providing you with real-time tracking and notification.",
  },
  {
    name: "Timely delivery",
    icon: NeTimely,
    message:
      "We are committed to delivering within the specified delivery window without compromising our stringent security standards.",
  },
];

export { VALUES };
