import React from "react";
import "./styles.scss";
import Main from "../../layouts/Main";
import TitleText from "../../components/TitleText";
import Heading from "../../components/Heading";
import { Helmet } from "react-helmet";

const LIGHT = "text-primary-500 font-normal xl:font-normal md:font-normal lg:font-normal xl:font-normal"
const BOLD = "text-secondary-500 font-semibold xl:font-semibold md:font-semibold lg:font-semibold xl:font-semibold"

const Contact = () => {
  return (
    <Main>
      <Helmet>
        <title>Contact | NewEra</title>
        <meta name="keywords" content="" />
      </Helmet>
      <div className={"min-h-screen h-full"}>
        <section className={"sm:pt-32 pt-24 container home-header"}>
          <div className={"flex flex-wrap"}>
            <div className={"w-full"}>
              <div className={"w-full text-left mb-16"}>
                <h4 className={"text-heading-sm md:text-heading-md lg:text-heading-lg xl:text-heading-xl "}>
                  <span className={LIGHT}>CONTACT </span>
                  <span className={BOLD}>US </span>
                </h4>
              </div>
              <div className={"contact-ads pl-[4rem] pr-[4rem] sm:h-[48rem] h-[20rem]"}></div>
            </div>
          </div>
        </section>

        <section className={"my-20 relative"}>
          <div className="container">
            <div className={"flex flex-wrap mt-20"}>
              <div className={"w-full"}>
                <div className={"flex flex-wrap justify-center duration-300"}>
                  <div className={"w-full sm:w-6/12"}>
                    <Heading>
                      Let’s talk <br className="xl:block hidden" />about your <br className="xl:block hidden" />next delivery!
                    </Heading>
                    <p className={"w-full xl:w-9/12 text-body-lg xl:text-body-xl mt-12 mb-6 font-light xl:leading-[40px] sm:pr-[8rem]"}>
                      Get in touch with our sales team and we’ll help you customize a unique logistics service to get your sensitive and confidential documents to their destination with the utmost care.
                    </p>
                  </div>
                  <div className={"w-full sm:w-6/12"}>
                    <TitleText text={"T: +234-1-342-9192"} isFirstItem={true} textColor={"text-[#707070]"} />
                    <TitleText text={"E: info@newera.com"} textColor={"text-[#707070]"} />
                    <TitleText text={"5 Onitsha Cres, Garki 900103, Abuja, Federal Capital Territory, Nigeria"} underline={false} isLastItem={true} textColor={"text-[#707070]"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Main>
  );
};

export default Contact;