import React from "react";
import "./styles.scss";
import NeLogoLight from "../../assets/icons/ne-logo-light.svg";
import { SOCIAL_ICONS } from "../../utils/footer";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={"bg-secondary-500 sm:pb-24 pb-4"}>
      <div className={"container sm:pt-24 pt-4"}>
        <div className={"w-full sm:grid sm:grid-cols-4 sm:gap-12 sm:text-left text-center"}>
          <div className={"w-full"}>
            <div className="sm:block flex flex-col items-center sm:mt-0 mt-10">
              <a href={"/#"}>
                <img
                  className={"lg:h-8 h-6"}
                  src={NeLogoLight}
                  alt={"Logo"}
                />
              </a>
              <div className={"mt-2 sm:mt-6"}>
                <div className={"sm:mr-10 mr-0 sm:mt-0 mt-4 mb-8"}>
                  <ul className={"flex gap-x-4"}>
                    {SOCIAL_ICONS.map((si, i) => (
                      <a
                        key={i + "icon"}
                        href={si.url}
                        className={"footer-icon"}
                      >
                        <img alt={"instagram"} src={si.icon} />
                      </a>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <p className="sm:block hidden text-white mt-20">@ copyright 2023 NewEra</p>
          </div>
          <div className={"w-full sm:mt-0 mt-10"}>
            <div className="sm:text-body-2xl text-body-lg font-normal">
              <NavLink to={"/services"}>
                <p className="text-white">Services</p>
              </NavLink>
              <NavLink to={"/location"}>
                <p className="text-white">Location</p>
              </NavLink>
              <NavLink to={"/about"}>
                <p className="text-white">About</p>
              </NavLink>
            </div>
          </div>
          <div className={"w-full sm:mt-0 mt-10"}>
            <div className="sm:text-body-2xl text-body-lg font-normal">
              <p className="text-white">Contact</p>
              <p className="text-white">T: +234 (0) 667 887 8879</p>
              <p className="text-white">E: info@newera.ng</p>
            </div>
          </div>
          <div className={"w-full sm:mt-0 mt-10"}>
            <div className="sm:text-body-2xl text-body-lg font-normal">
              <p className="text-white">Terms & Conditions</p>
              <p className="text-white">Policy</p>
            </div>
          </div>
          <p className="sm:hidden block text-white my-10 text-body-sm">@ copyright 2023 NewEra</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
