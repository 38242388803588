import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import "./styles.scss";
import NeArrowRight from "../../assets/imgs/ne-arrow-right.png";
import NeMapBg from "../../assets/imgs/ne-map-bg.png";
import NeMap from "../../assets/imgs/ne-map.png";
import NeHobo from "../../assets/imgs/ne-hobo.png";
import NeNipost from "../../assets/imgs/ne-nipost.png";
import NeSwg from "../../assets/imgs/ne-swg.png";
import NeDis from "../../assets/imgs/ne-dis.png";
import NePayfixy from "../../assets/imgs/ne-payfixy.png";
import NeHoboLogo from "../../assets/icons/ne-hobo-logo.svg";
import NeNipostLogo from "../../assets/imgs/ne-nipost-logo.png";
import { useNavigate } from "react-router-dom";
import Main from "../../layouts/Main";
import Button from "../../components/Button";
import { VALUES } from "../../utils/content";
import TitleText from "../../components/TitleText";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper";

const verbs = [{ color: "bg-[#E3D985]", word: "SECURELY" }, { color: "bg-[#D8E1FF]", word: "CONFIDENTIALLY" }, { color: "bg-[#E3D76F]", word: "RESPONSIBLY" }];
const INACTIVE_STATE = "text-[#707070] text-body-xl sm:text-body-2xl xl:text-heading-sm font-light sm:font-light xl:font-light xl:mb-4 cursor-pointer";
const ACTIVE_STATE = "text-primary-500 text-body-xl sm:text-body-2xl xl:text-heading-sm xl:font-normal sm:font-normal font-normal xl:mb-4 cursor-pointer transition ease-in-out hover:translate-x-6 hover:scale-110 duration-300";
const LIGHT = "text-primary-500 font-normal xl:font-normal md:font-normal lg:font-normal xl:font-normal"
const BOLD = "text-secondary-500 font-semibold xl:font-semibold md:font-semibold lg:font-semibold xl:font-semibold"

const Home = () => {
  const swiperRef = React.useRef(null);
  const [value, setValue] = useState(VALUES[0]);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      counter === verbs.length - 1 ? setCounter(0) : setCounter(counter + 1);
    }, 2000);
    return () => clearInterval(interval);
  }, [counter]);

  const handleOver = (index) => {
    setValue(VALUES[index]);
  }

  const reset = () => {
    setValue(VALUES[0]);
  }

  return (
    <Main>
      <Helmet>
        <title>Home | New Era</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"min-h-screen h-full"}>
        <section className={"sm:pt-32 pt-24 container home-header"}>
          <div className={"flex flex-wrap"}>
            <div className={"w-full"}>
              <div className={"sm:block hidden w-full text-left sm:mb-16 mb-8"}>
                <h4 className={"m-0 text-heading-sm md:text-heading-md lg:text-heading-lg xl:text-heading-xl"}>
                  <span className="text-primary-500 font-normal xl:font-normal md:font-normal lg:font-normal xl:font-normal pr-2">WE DELIVER</span>
                  <span className={`text-secondary-500 p-3 font-semibold xl:font-semibold md:font-semibold lg:font-semibold xl:font-semibold px-4 ${verbs[counter].color}`}>{verbs[counter].word}</span>
                </h4>
                <h4 className={"mt-2 text-primary-500 text-heading-sm md:text-heading-md lg:text-heading-lg xl:text-heading-xl font-normal xl:font-normal md:font-normal lg:font-normal xl:font-normal"}>
                  TO THE LAST MILE
                </h4>
              </div>
              <div className={"sm:hidden block w-full text-left sm:mb-16 mb-8"}>
                <h4 className={"text-heading-sm md:text-heading-md lg:text-heading-lg xl:text-heading-xl "}>
                  <span className={LIGHT}>WE DELIVER </span>
                  <span className={BOLD}>SECURELY</span>
                  <span className={LIGHT}>, </span>
                  <span className={BOLD}>CONFIDENTIALLY</span>
                  <span className={LIGHT}>, </span>
                  <span className={BOLD}>RESPONSIBLY </span>
                  <span className={LIGHT}>TO THE LAST MILE</span>
                </h4>
              </div>
              <div className={"header-ads xl:px-[4rem] px-[2rem] xl:pt-[24rem] pt-[12rem] pb-[4rem]"}>
                <div className="sm:block hidden w-1/2">
                  <p className={"text-body-2xl xl:text-heading-xs mt-6 font-normal lh-5 text-white"}>
                    NewEra is Nigeria's preferred logistics partner for secure last-mile delivery of sensitive and confidential documents.
                  </p>
                </div>
                <div className={"mt-10 sm:flex hidden gap-x-4"}>
                  <Button
                    state={"PRIMARY"}
                    text={"Request Quote"}
                    onClick={() => navigate("/contact")}
                  />
                  <Button
                    state={"PRIMARY"}
                    text={"Talk to sales"}
                    onClick={() => navigate("/contact")}
                  />
                </div>
              </div>
              <div className="sm:hidden block">
                <p className={"text-body-xl mt-6 font-normal lh-5 text-secondary-500"}>
                  NewEra is Nigeria's preferred logistics partner for secure last-mile delivery of sensitive and confidential documents.
                </p>
              </div>
              <div className={"mt-10 sm:hidden flex gap-x-4"}>
                <Button
                  state={"SECONDARY"}
                  text={"Request Quote"}
                  onClick={() => navigate("/contact")}
                />
                <Button
                  state={"SECONDARY"}
                  text={"Talk to sales"}
                  onClick={() => navigate("/contact")}
                />
              </div>
            </div>
          </div>
        </section>

        <section className={"container sm:mt-24 mt-12"}>
          <h3 className={"text-secondary-500 sm:text-heading-xs text-body-2xl sm:font-normal font-normal sm:mb-4"}>OUR VALUES</h3>
          <div className={"flex flex-wrap sm:justify-center"}>
            <div className={"sm:block hidden w-7/12"}>
              <div className={"text-left h-full"}>
                <p className={"text-primary-500 text-heading-md font-medium mb-4 font-normal mt-10"}>0{VALUES.indexOf(value) + 1} <span className={VALUES.indexOf(value) === 0 ? "text-primary-500" : "text-[#E6E6E6]"}> - 06</span></p>
                {
                  VALUES?.map((v, index) => <div key={v?.name} className="flex justify-between items-center mt-10">
                    <p className={v?.name === value?.name ? ACTIVE_STATE : INACTIVE_STATE} onMouseOver={() => handleOver(index)} onMouseLeave={reset}>{v?.name}</p>
                    {value.name === v?.name && <img src={NeArrowRight} alt="icon" className="h-4 sm:h-6 xl:h-10" />}
                  </div>)
                }
              </div>
            </div>
            <div className={"sm:block hidden w-5/12"}>
              <div className={"text-left h-full"}>
                <div className={"xl:px-5 h-full"}>
                  <div className={"rounded-[36px] bg-primary-100 px-4 xl:px-10 sm:py-10 h-full flex items-center flex-col justify-between"}>
                    <img src={value?.icon} alt={"icon"} className="mt-16 xl:h-[14rem] h-[8rem]" />
                    <p className={"text-body-lg xl:text-body-xl mb-6 font-light xl:leading-[32px] text-center"}>
                      {value?.message}
                    </p>
                    <div className={"mb-16"}>
                      <Button
                        state={"SECONDARY"}
                        text={"Talk to sales"}
                        onClick={() => navigate("/contact")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"sm:hidden w-full"}>
              <div className={"text-left"}>
                <p className={"text-primary-500 text-body-xl font-medium mb-4 font-normal mt-10"}>0{VALUES.indexOf(value) + 1} <span className={"text-[#E6E6E6]"}> - 06</span></p>
              </div>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                freeMode={true}
                modules={[FreeMode, Pagination]}
                className="sm:grid grid-cols-2 gap-10 h-full"
                loop={true}
                ref={swiperRef}
                onRealIndexChange={(element) => setValue(VALUES[element.activeIndex])}
              >
                {VALUES.map(v =>
                  <SwiperSlide key={v.name}>
                    <div className="flex justify-between items-center">
                      <p className="text-primary-500 text-body-xl font-medium mb-4">{v?.name}</p>
                    </div>
                    <div className={"rounded-[36px] bg-primary-100 px-4 xl:px-10 sm:py-10 flex items-center flex-col justify-between h-[30rem]"}>
                      <img src={v?.icon} alt={"icon"} className="mt-16 xl:h-[14rem] h-[8rem]" />
                      <p className={"text-body-lg xl:text-body-xl mb-6 font-light xl:leading-[32px] text-center"}>
                        {v?.message}
                      </p>
                      <div className={"mb-16"}>
                        <Button
                          state={"SECONDARY"}
                          text={"Talk to sales"}
                          onClick={() => navigate("/contact")}
                        />
                      </div>
                    </div>

                  </SwiperSlide>)}
              </Swiper>
            </div>
          </div>
        </section>

        <section className={"sm:mt-16 xl:mt-24 mt-12 relative"}>
          <div className="container">
            <h3 className={"sm:block hidden text-secondary-500 text-heading-xs font-normal mb-4 sm:leading-10"}>OUR COVERAGE</h3>
            <div className={"sm:flex flex-wrap mt-12 hidden"}>
              <div className={"w-full"}>
                <div className={"flex flex-wrap justify-center duration-300"}>
                  <div className={"w-full sm:w-5/12"}>
                    <h4 className={"text-heading-md sm:text-heading-md xl:text-heading-xl text-primary-500 sm:mb-0 mb-2 font-semibold sm:font-semibold"}>
                      We deliver<br />across all<br />36 states<br />of Nigeria.
                    </h4>
                    <p className={"text-body-lg xl:text-body-xl mt-12 font-light xl:leading-[40px] xl:pr-[8rem]"}>
                      Our partnerships ensure we can deliver anywhere in the country and guarantee a safe journey for your documents across each state.
                    </p>
                    <div className={"flex gap-x-4 mt-6"}>
                      <Button
                        state={"SECONDARY"}
                        text={"Request Quote"}
                        onClick={() => navigate("/contact")}
                      />
                      <Button
                        state={"SECONDARY"}
                        text={"Talk to sales"}
                        onClick={() => navigate("/contact")}
                      />
                    </div>
                  </div>
                  <div className={"w-full sm:w-7/12"}>
                    <img className="h-[9rem] sm:h-[30rem] xl:h-[34rem]" src={NeMap} alt="map-bg" />
                    <img className="h-[30rem] sm:h-[50rem] xl:h-[80rem] absolute right-0 top-[-1rem] sm:top-[0rem] xl:top-[-10rem] z-[-999]" src={NeMapBg} alt="map-bg" />
                  </div>
                </div>
              </div>
            </div>
            <div className={"sm:hidden flex flex-wrap"}>
              <div className={"w-full"}>
                <div className={"flex flex-wrap duration-300"}>
                  <div className={"w-full"}>
                    <img className="h-[20rem]" src={NeMap} alt="map-bg" />
                    <img className="h-[20rem] absolute right-0 top-[-1rem] z-[-999]" src={NeMapBg} alt="map-bg" />
                  </div>
                  <h3 className={"sm:hidden block text-secondary-500 text-body-2xl font-normal mt-10 sm:leading-10 mb-2"}>OUR COVERAGE</h3>
                  <div className={"w-full"}>
                    <h4 className={"font-bold text-heading-sm text-primary-500 mb-2"}>
                      We deliver <br className="sm:block hidden" />across all <br className="sm:block hidden" />36 states <br className="sm:block hidden" />of Nigeria.
                    </h4>
                    <p className={"text-body-lg xl:text-body-xl mt-6 mb-6 font-light xl:leading-[40px]"}>
                      Our partnerships ensure we can deliver anywhere in the country and guarantee a safe journey for your documents across each state.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={"sm:block hidden mt-20 base-ads"}>
          <div className={"container"}>
            <div className={"px-0 sm:px-2 xl:px-[4rem] pt-[10rem] sm:pt-[25rem] xl:pt-[50rem] pb-[4rem]"}>
              <div className="w-full sm:w2/3 xl:w-1/2">
                <p className={"text-body-lg sm:text-heading-lg xl:text-heading-xl mt-6 xl:font-normal sm:font-normal font-normal text-white"}>
                  SECURE, SIMPLE & STRAIGHTFORWARD LOGISTICS
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={"sm:hidden block mt-20 base-ads-mobile"}>
          <div className={"container"}>
            <div className={"pt-[6rem] pb-[1rem] pl-[1rem] pr-[1rem]"}>
              <div className="w-2/3">
                <p className={"text-heading-xs mt-6 font-light text-black"}>
                  Secure, Simple & Straightforward Logistics
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={"sm:py-12 xl:py-24 py-4"}>
          <div className={"container"}>
            <h4 className={"sm:block hidden text-center text-text-heading-xs sm:text-heading-md xl:text-heading-xl text-secondary-500 mb-16 xl:mb-24 mt-4 xl:mt-10 font-bold"}>
              Our Partners
            </h4>
            <h4 className={"sm:hidden block text-center text-heading-sm font-medium text-secondary-500 mb-20 mt-10"}>
              OUR PARTNER
            </h4>
            <div className="sm:grid hidden grid-cols-5 gap-12">
              <img className={"lg:h-8 h-6 m-auto"} src={NeHobo} alt={"Logo"} />
              <img className={"lg:h-10 h-6 m-auto"} src={NeNipost} alt={"Logo"} />
              <img className={"lg:h-10 h-6 m-auto"} src={NeSwg} alt={"Logo"} />
              <img className={"lg:h-10 h-6 m-auto"} src={NeDis} alt={"Logo"} />
              <img className={"lg:h-10 h-6 m-auto"} src={NePayfixy} alt={"Logo"} />
            </div>
            <div className="sm:hidden grid grid-cols-2 gap-12 mb-10">
              <img className={"lg:h-auto h-8 m-auto"} src={NeHoboLogo} alt={"Logo"} />
              <img className={"lg:h-auto h-8 m-auto"} src={NeNipostLogo} alt={"Logo"} />
            </div>
          </div>
        </section>

        <section className={"mt-10"}>
          <div className="container">
            <h3 className={"text-secondary-500 sm:text-heading-xs text-body-2xl sm:font-normal font-normal mb-4 sm:leading-10"}>WHO WE SERVE</h3>
            <div className={"flex flex-wrap sm:mt-12 mt-4"}>
              <div className={"w-full"}>
                <div className={"flex flex-wrap justify-center duration-300"}>
                  <div className={"w-full sm:w-1/2 xl:w-7/12"}>
                    <h4 className={"text-heading-md sm:text-heading-md xl:text-heading-xl text-primary-500 sm:mb-0 mb-2 font-semibold sm:font-semibold"}>
                      From safe <br className="sm:block hidden" />hands to your <br className="sm:block hidden" />hands
                    </h4>
                    <p className={"sm:w-full xl:w-2/3 w-full text-body-lg xl:text-body-xl sm:mt-12 mt-6 mb-6 font-light xl:leading-[40px] sm:pr-[8rem]"}>
                      We accommodate the unique logistics needs of diverse organizations and institutions of all sizes, as well as customize our services to meet individual courier requirements.
                    </p>
                    <div className={"mt-6 sm:flex hidden gap-x-4"}>
                      <Button
                        state={"SECONDARY"}
                        text={"Learn More."}
                      />
                    </div>
                  </div>
                  <div className={"w-full sm:w-1/2 xl:w-5/12"}>
                    <TitleText text={"Schools & Educational Institutions"} isFirstItem={true} />
                    <TitleText text={"Corporate Companies"} />
                    <TitleText text={"Government Agencies"} />
                    <TitleText text={"Bulk Senders"} />
                    <TitleText text={"Individuals (Personal Courier Services)"} underline={false} isLastItem={true} />
                  </div>
                  <div className={"mt-6 sm:hidden flex gap-x-4"}>
                    <Button
                      state={"SECONDARY"}
                      text={"Learn More."}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={"sm:mt-32 mt-16 sm:prof-bg prof-mobile-bg"}>
          <div className={"container"}>
            <div className={"px-[1rem] sm:px-[4rem] sm:pt-[10rem] xl:pt-[24rem] pt-[2rem] sm:pb-[8rem] pb-[2rem]"}>
              <div className="sm:w-4/5 xl:w-3/5 w-full">
                <p className={"text-heading-xs sm:text-heading-md xl:text-heading-xl mt-6 font-medium text-white"}>
                  Professional logistics with a personal touch.
                </p>
                <p className={"text-body-sm sm:text-body-lg xl:text-heading-xs mt-6 font-normal lh-5 text-white"}>
                  When it comes to your shipments, you'll never feel alone. With us, you have more than just a courier; you have a partner who is here for you every step of the way.
                </p>
                <div className={"sm:flex hidden mt-10 flex gap-x-4"}>
                  <Button
                    state={"PRIMARY"}
                    text={"Request Quote"}
                    onClick={() => navigate("/contact")}
                  />
                  <Button
                    state={"PRIMARY"}
                    text={"Talk to sales"}
                    onClick={() => navigate("/contact")}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Main>
  );
};

export default Home;